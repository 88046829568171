/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import { Footer } from "./Footer"
import "../css/typography.css"
import "../css/tailwind.dist.css"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <div className="w-full bg-azul-900">
      {/* <div
        className="min-h-screen"
        style={{
          background:
            "radial-gradient(57.5% 57.5% at 50% 100%, #FFF4BC 0%, #ED6D68 25.52%, #3C44D1 45.31%, #1E1453 100%)",
        }}
      >
        <Header /> */}
      <main>{children}</main>
      {/* </div> */}

      {/* <Footer /> */}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
